@media only screen and (max-width: 600px) {
  .login-container { width: 320px; margin: 15px auto;}

}

@media only screen and (max-width: 1024px) {
.dashboard-block{}

}
@media only screen and (max-width: 960px) {
.hide-in-ml{ display: block;}
.dashboard-container{
  width: 100%;
  height: auto;
  margin: 100px auto;
  .dashboard-block{ width: 470px;
    li{
      width: 150px;
      margin: 0 5px 10px 0;
      a{
        width: auto;
        img{ width: 30%;}
      }
    }
  }
}

.head-right-section{
  .user-thumbnail{
     text-align: center;
  }
  .logout-btn {
    float: left;
    width: 100%;
  }
  .user-name{
    margin: 27px 0 0px 0px;

    .clearfix {
      padding-right: 11px;
    }
  }
  .user-name,a{ color: #fff;}
}

footer{ text-align: center;
  .float-right{ float: none !important;}
}
.js-toggle-sidebar{ cursor: pointer;}
.sidebar{
  top: 0;
  width: auto;
  z-index: 999;
  height: 100%;
  transform: translateX(-100%);
  transition: transform 140ms ease-in-out;
  &.open {
     transform: translateX(0);
  }
}


.container-with-sidebar{
  width: 100%;
  left: 0;
  .middle-content {
    padding: 20px 22px;
  }
  footer {
	   position: relative
  }

}
.hide-mobile-view{
  display: none;
}

.hide-for-desktop{ display: block;}

.signature-pad--actions .btn{
  margin: 5px;
}

}
@media only screen and (max-width: 480px) {

  .dashboard-container{
    width: 100%;
    height: auto;
    margin: 25px auto;
    .dashboard-block{ width: 100%;
      li{
        width: 100%;
        margin: 0 0px 15px 0px;
        a{
          width: auto;
          img{ width: 22%;}
        }
      }
    }
  }
  .feature-list {
    margin: 0 auto;
    width: 80%;
    padding: 0;
    li {
      width: 100%;
    }
  }
  .btn-from-save-progress { white-space: normal;}
  .hide-in-ml{ display: block;}
}

.ion-android-menu{ font-size: 32px; padding:20px 30px; float: right;}

.name-of-person {
  #signatory {
    background-color: #fafaa4;
    color: #ff3234;
    &::placeholder {
      color: #ff3234;
    }
  }
}
