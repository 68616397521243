body {
  background: $body-bg;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;

  &.stick-footer-space {
    margin-bottom: 60px;
  }
}

.header {
  border-bottom: 4px solid $header-border;
  height: 90px;
  width: 100%;
  text-align: center;
  background: $white-bg;
  -webkit-box-shadow: 0 5px 25px 0 rgba(0,0,0,0.1);
  box-shadow: 0 5px 25px 0 rgba(0,0,0,0.1);
}

.sub-name {
  border-left: 1px solid #333333;
  padding: 2px 0 2px 15px;
  margin-left: 20px;
  font-weight: bold;
  color: #555555;
}

.content {
  background: $white-bg;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);

}

footer {
  background: $white-bg;
  padding: 15px;
  font-size: $font-size-xs;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.alert-warning {
  background: #fcf9d6;
  border-color: #fcf9d6;
  color: #333;
}

.inactive-page {
  margin-top: 10%;
  span {
    display: inline-flex;
    align-items: center;
    i {
      font-size: 46px;
      margin: 15px;
    }
  }
}

.feature-list {
  margin: 0 auto;
  width: 100%;
  padding: 0 !important;

  li {
    float: left;
    margin: 0px 20px 20px 0px;
    width: 390px;
    list-style: none;

    a {
      background: #bfbfbf;
      text-align: left;
      display: block;
      width: 345px;
      color: #555;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      padding: 14px 12px 12px 14px;
      vertical-align: middle;
    }
    a:hover{
      text-decoration: none;
      background: #AAA;
    }

    ion-icon {
      font-size: 36px;
    }
  }
}



ion-icon {
  font-size: 14px;
}

.customer-form-wrapper {
  display: flex;
  flex-direction: row;

  .left-side-wrapper {
    background: $white-bg;
  }
}
/*Form Wizard*/

.bs-wizard {
  padding: 20px 40px;

  .bs-wizard-step {
    padding: 0;
    position: relative;

    &.complete {
      .bs-wizard-dot {
        background: #e3d32d;
        color: #000;
      }
    }

    .bs-wizard-stepnum {
      color: #595959;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .bs-wizard-info {
      color: #999;
      font-size: 14px;
    }

    .bs-wizard-dot {
      position: absolute;
      width: 30px;
      height: 30px;
      padding: 5px;
      display: block;
      background: #005030;
      top: 18px;
      left: 50%;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;
      color: #fff;

      &:after {
        content: ' ';
        width: 14px;
        height: 14px;
        border-radius: 50px;
        position: absolute;
        top: 8px;
        left: 8px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .progress {
      position: relative;
      border-radius: 0;
      height: 1px;
      box-shadow: none;
      margin: 20px 0;

      .progress-bar {
        width: 0;
        box-shadow: none;
        background: #fbe8aa;
      }
    }
  }
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}

.right-side-col {
  background: $white-bg;
}

.form-side-step {
  ul {
    margin: 0 0 0 0;
    padding: 0;
    width: 100%;

    li {
      list-style: none;
      padding: 15px 40px 15px 30px;
      border: 1px solid #eaeaea;
      border-top: 0;
      position: relative;

      &.selected {
        background: #009933;
        //margin-left: -20px;
        color: #fff;
        padding-left: 40px;

        &::before {
          background: #f7ed70;
        }
      }

      &::before {
        content: "";
        position: absolute;
        background: #005030;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
      }

      .check {
        position: absolute;
        font-size: 20px;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.middle-form-wrapper {
  padding: 60px;
  background: #e8e8e8;

  .form-title {
    font-size: 20px;
    margin: 0 0 30px;
    padding: 10px 15px;
    font-weight: bold;
    background: #f0ecb9;
    border-radius: 3px;
  }

  h5 {
    color: #005030;
    font-size: $font-size-base;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .esign-block {
    background: #f7f7f7;
    border-radius: 5px;
    text-align: center;
    padding: 10px 10px 40px 10px;
  }
}

.form-check {
  display: inline-block;
  min-width: 130px;

  .form-check-label {
    padding-left: 15px;
    padding-right: 10px;
    cursor: pointer;
  }
}

input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #fff;
  border-radius: 50%;
}
// :hover input[type=radio] ~ .checkmark {
//     background-color: #ccc;
// }

input[type=radio]:checked ~ .checkmark {
  background-color: #005030;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input[type=radio]:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  top: 5px;
  left: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}





.hilite{
	background-color: #f3e6b1;
	padding: 10px;
}

.landing-container{
  padding-left: 160px;
  padding-right: 160px;
}

h4.signing-page{
  color: #005030 !important;
  text-align:left;
  margin-bottom: 10px;
  margin-top:20px;
  font-weight: 900;
}

#alert-success{
  display:none;
}
#alert-danger{
  display:none;
}

#submit-all-forms, #download-button{
  font-size: 16px;
  line-height:44px;
  padding-left: 36px;
  padding-right:36px;
  margin-bottom: 80px;
}
#submit-all-forms i, #download-button i{
  font-size: 22px;
  margin-right: 12px;
}
