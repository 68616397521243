.login-bg {
  background: url("../images/login-body-bg.jpg") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-container {
  width: 386px;
  background: $white-bg;
  margin: 0 auto;
  border-radius: 3px;
  padding: 50px;
  -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.4);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.4);

  .logo {
    margin: 0 0 40px;
    text-align: center;
    .logo-txt{
      margin: 0 0 0 130px;
      font-size:$font-size-md
    }
  }

  // .input-group{
  //  .alert{ margin-bottom: 0; margin-top: 15px;}
  // }
  .input-group-addon{
    background: #f8f8f8;
    width: 37px;
    padding: 3px 0;
    text-align: center;
    display: block;
    border-color: #f7f7f7;

  }
  .forgot-link{
    color: #272c33;
    text-decoration: underline;
    font-size: $font-size-md;
    display: inline-block;
    &:hover{
      text-decoration: none;
    }
  }
}
