.admin-logo {
  padding: 8px 0px 0px 30px;
}

.head-right-section {
  -webkit-box-shadow: -4px 0px 20px 2px rgba(0, 0, 0, 0.05);
  box-shadow: -4px 0px 20px 2px rgba(0, 0, 0, 0.05);
  height: 90px;

  .user-thumbnail {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 40px;
    margin: 22px;
    background: #e8e8e8;
    line-height: 40px;
    font-size: 24px;
    text-align: center;
  }

  .user-name {
    margin: 27px 0 25px 25px;
    font-size: $font-size-md;
    color: #555555;
  }
}

.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: #{"calc(100vh - 140px)"};

  .dashboard-block {
    margin: 0;
    padding: 0;

    li {
      float: left;

      background: $white-bg;
      margin-right: 40px;
      list-style: none;
      border-radius: 3px;

      -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.03);
      box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.03);

      a {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        text-transform: uppercase;
        color: #005030;
        text-align: center;
        width: 215px;
        min-height: 160px;
        flex-direction: column;
        font-weight: bold;

        img {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.sidebar {
  width: 280px;
  background: $sidebar-bg;
  height: #{"calc(100vh - 90px)"};
  position: fixed;
}

.side-navigation {
  ul {
    margin: 0;
    padding: 15px 0;

    li {
      list-style: none;
      position: relative;

      a {
        color: $side-nav-color;
        font-size: $font-size-md;
        padding: 8px 30px 8px 30px;
        display: block;

        &.active {
          &::before {
            border-left: 5px solid #f2e43e;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 36px;
          }

        }
      }
    }
  }
}


.table {
  thead {
    border-radius: 6px;
    background: #1e1e1e;
    color: #fff;
  }

  tr {
    td {
      font-size: $font-size-md;
      color: #555555;
      word-break: break-all;
    }
  }
}

.table-hover {
  tbody {
    tr {
      &:hover {
        background: #d4e1dc;
      }

    }
  }
}

table th:first-child {
  border-radius: 5px 0 0 5px;
}

table th:last-child {
  border-radius: 0 5px 5px 0;
}

.sub-title {
  color: #444444;
}

.user-data {
  color: #9c9c9c;
  font-size: $font-size-md;
  margin: 0;
}

.gray-wrapper {
  background: #e8e8e8;
  padding: 15px;
  border-radius: 5px;
}

.formManagerActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.formManager__fields {
  margin-top: 1rem;
}

.addFormFieldBtn {
  margin-left: auto;
}

.formField {
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc;
  background-color: #e8e8e8;
  padding-left: 10px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  &_buttons {
    display: flex;
    height: 100%;
  }

  &_title {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    //text-transform: uppercase;
    display: flex;
    flex-grow: 1;
    align-items: center;
    .icon {
      font-size: 24px;
      color: $text-primary;
    }
  }

  &_delete_btn {
    margin-left: auto;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none !important;
    box-shadow: none;

    &:active, :hover, :focus {
      outline: none !important;
    }

    .icon {
      font-size: 24px;
      color: $text-primary;
      line-height: 1;
    }
  }

  &_collapse_btn {
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;
    background-color: $btn-primary;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 45px;
    height: 35px;
    align-items: center;
    border: none;
    outline: none !important;
    box-shadow: none;

    &:active, :hover, :focus {
      outline: none !important;
    }

    &.collapsed > .when-opened,
    &:not(.collapsed) > .when-closed {
      display: none;
    }
  }

  &_collapseIcon {
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
    height: 25px;
    width: 25px;
    color: #fff;

    &--expanded {
      @extend .formField_collapseIcon;
      transform: rotate(180deg);
    }
  }

  &_collapseChevron {

  }

  &_expandedForm {
    margin-bottom: 0.5em;
    background-color: #f0ecb9;
    padding: 1rem;
    border-radius: 4px;

    &TransactionTypes {
      display: flex;
      flex: 1;
    }

    &States {
      display: flex;
      flex-direction: column;
      height: 306px;
      width: 45rem;
      flex-wrap: wrap;
    }
  }
}

.optionField {
  background-color: $sidebar-bg !important;
  color: white !important;
  .icon {
    font-size: 18px!important;
    color: #ffffff!important;
    margin-left: 10px;
  }
}

.stateCheckbox {
  margin: 10 10;
}

.card {
  margin-bottom: 12px;
  border: 1px solid transparent;
  background: transparent;

  .card-header {
    background: #e8e8e8;
    border-radius: 5px;
    padding: 0;

    .icon {
      font-size: 24px;
      color: $text-primary;

    }

    .left-data {
      padding: 5px 15px;
      float: left;
      line-height: 30px;
      width: 90%;
      text-transform: capitalize;
    }

    .btn-link {
      background: $btn-primary;
      color: #fff;
      border-radius: 0 5px 5px 0;
      padding: 9px 17px;
      font-size: $font-size-lg;
    }
  }

  .card-body {
    border-radius: 5px;
    margin-top: 5px;

    &.yellow-bg {
      background: #f0ecb9;
    }

    &.inner-card-body {
      background: #cfc875;
      margin-top: 0px;
    }

    .card-header {
      background: #272c33;
      border-radius: 5px;
      padding: 0;
      color: $text-white;
      height: auto;

      .icon {
        font-size: $font-size-xl;
        color: $text-white;

      }
    }
  }

  .form-row-wrapper {
    margin: -15px -5px -15px -5px;

    .form-row-list {
      background: #f0ecb9;
      border-radius: 5px;
      padding: 5px;
      line-height: 30px;
      font-weight: 600;
      font-size: $font-size-md;
      margin-bottom: 5px;
      color: #555555;

      .icon {
        font-size: 24px;

      }
    }
  }

}

.acc-for-form-manager button.collapsed i.col-expand-icon:before {
  content: '\F123'
}

.acc-for-form-manager button i.col-expand-icon:before {
  content: '\F126';
}

.hide-for-desktop {
  display: none;
}

.page-item.active .page-link {
  border: 1px solid #009933;
  background: #009933;
  color: #fff;
}

.page-link {
  color: #009933;
}