.text-primary {
  color: $text-primary !important;
}
.text-default{
  color: #2a2a2a;
}

.wrapper-xl {
  padding: 50px;
}

.wrapper-lg {
  padding: 40px;
}

.wrapper {
  padding: 30px;
}

.wrapper-xs {
  padding: 5px;
}

.wrapper-sm {
  padding: 3px;
}

.m-t {
  margin-top: 15px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-xl {
  margin-top: 30px;
}

.m-t-90 {
  margin-top: 90px;
}

.m-b-xl {
  margin-bottom: 40px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-b-md {
  margin-bottom: 20px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b-xs {
  margin-bottom: 5px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r {
  margin-right: 15px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-l-xs{ margin-left: 5px;}
.m-l-xl{ margin-left: 30px;}
.m-l-xxl{ margin-left: 40px;}

.p-r-n{ padding-right: 0;}

.btn {
  padding: 5px 20px;
  font-size: $font-size-xs;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;

  &.btn-primary {
    background: $btn-primary;
    border-color: $btn-primary;
    border-bottom: 2px solid #072d1e;

    &:active,
    &:hover {
      background: $btn-primary-hover !important;
      border-color: $btn-primary-hover !important;
      border-bottom: 2px solid #072d1e !important;
    }
  }

  &.btn-default {
    background: $btn-default;
    border-color: $btn-default;
    border-bottom: 2px solid #252525;
    color: #fff;

    &:hover {
      background: $btn-default-hover;
      border-color: $btn-default-hover;
      border-bottom: 2px solid #072d1e;
    }
  }

  &.btn-secondary {
    background: #e3d32d;
    border-color: #e3d32d;
    border-bottom: 2px solid #aa9e1f;
    color: #000;

    &:hover {
      background: #e3d32d;
      border-color: #e3d32d;
      border-bottom: 2px solid #aa9e1f;
    }
  }

  &.btn-wrap {
    white-space: normal;
  }
}

label {
  color: #555555;
  font-size: $font-size-base;
  font-weight: bold;
  padding-bottom: 5px;
}

select {
  -webkit-appearance: menulist;
}
.filter-select {
  -webkit-appearance: none;
}
.form-group {
  label {
    color: #555555;
    font-size: $font-size-base;
    font-weight: bold;
    padding-bottom: 5px;

    &.form-check-label {
      font-weight: 400;
    }
  }
}

label {
  font-size: $font-size-base;
}

fieldset {
  legend {
    font-size: $font-size-base;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.form-control {
  border: 1px solid #f7f7f7;
  background: #f7f7f7;
  font-size: $font-size-base;
}

.font-size-sm {
  font-size: $font-size-sm;
}

.font-size-md {
  font-size: $font-size-md;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.droppable-wrapper {
  border: 1px dashed #959595;
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  text-align: center;

  p {
    padding: 0;
    margin: 0;
  }

  .ion-android-upload {
    color: #b9b9b9;
    font-size: 30px;
  }
}

a {
  color: $link;
  text-decoration: none;

  &:hover {
    color: $link;
  }
}

.breadcrumb {
  background: transparent;
  margin: 0;
  padding-left: 0;

  .breadcrumb-item {
    a {
      color: #777777;
    }

    &::before {
      content: ">";
    }

    &:first-child {
      &::before {
        content: "";
      }
    }
  }
}

.form-control-2 {
  position: relative;

  input,
  select {
    background: $white-bg;
    font-size: 14px;
    padding-left: 35px;
    height: 38px;
  }

  .icon {
    position: absolute;
    left: 15px;
    top: 5px;
    font-size: 20px;
  }
}

h2 {
  font-size: 22px;
  color: #444444;
  display: flex;
  align-items: center;

  i {
    font-size: 36px;
  }
}
h3{ font-size: 18px;
  font-weight: 800;
  text-transform: capitalize;
}
h3.m-b-xl {
  color: #005030 !important;
  margin-top: 60px;
  margin-bottom: 10px;
}
h4{ font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  color: #444444;
}
h4.m-b-xl {
  color: #005030 !important;
}

h5{
  font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #444444;
}

.container-with-sidebar {
  position: fixed;
  top: 90px;
  left: 280px;
  width: #{"calc(100% - 280px)"};
  height: #{"calc(100% - 90px)"};
  overflow: auto;

  .middle-content {
    padding: 20px 50px;
  }
}
.font-size-lg{ font-size: $font-size-lg;}
.font-size-xl{ font-size: $font-size-xl;}
.text-secondary{ color: $secondary-text;}
label{
  font-size: $font-size-md;
  margin-bottom: 0.1em;
}
.uppercase{ text-transform: uppercase;}
.capitalized{ text-transform: capitalize;}
.d-flex{ display: flex;}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #686868;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #686868;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #686868;
}
:-moz-placeholder { /* Firefox 18- */
  color: #686868;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {

}
.loader:before {
    content: "\F3B1";
    animation: spin 2s linear infinite;
}

.hide-in-ml {
  display: none;
}