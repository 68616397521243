
// Body
$body-bg : #f7f7f7;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 0.9rem;
$font-size-xs: 11px;
$font-size-sm: 12px;
$font-size-base: 13px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl : 18px;
$line-height-base: 1.6;


$white-bg: #fff;
$header-border: #005030;

$btn-primary: #009933;
$btn-primary-hover: #072d1e;

$btn-default: #6e6e6e;
$btn-default-hover: #252525;

$text-primary: #005030;

$link: #005030;

$sidebar-bg: #272c33;
$side-nav-color: #727f92;
$secondary-text : #777777;
$text-white: #ffffff;
